import React, {useMemo} from "react";
import styled from "styled-components";
import {useGetUserMob} from "../../../features/user/user.hooks";
import {ReactComponent as Home} from "../../../assets/SVG/tabs/icon_Home.svg";
import {ReactComponent as Abs} from "../../../assets/SVG/tabs/icon_absence.svg";
import {ReactComponent as Cren} from "../../../assets/SVG/tabs/icon_creaneaux.svg";
import {ReactComponent as Pl} from "../../../assets/SVG/tabs/icon_planning.svg";
import {ReactComponent as Mess} from "../../../assets/SVG/tabs/icon_messagerie.svg";
import {ReactComponent as Profil} from "../../../assets/SVG/tabs/icon_profil.svg";
import TimedOneNav from "../../molecules/TimedOneNav";
import TimedOneNavMessagerie from "../../molecules/TimedOneNavMessagerie";
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";

interface TabNavBarCtrlProps{
    className?:string;
}

interface OneNav{
    libelle:string;
    code:string;
    icon:React.ReactNode;
    link:string;
    auth:boolean;
    isMessagerie?:boolean;
    univers:string[];
}
/*
pl : BsFillCalendar;
messagerie: IoChatBubble
Accueil:
profil : BsPerson // BsPersonFill
Creneaux: HiSwitchHorizontal

* */

const TabNav:OneNav[] = [
    {code:'accueil', libelle:"Accueil", icon:<Home/>, auth:true, link:'dashboard', univers:["TIMED", "RESTIMED"]},
    {code:"abs",libelle:"absences", icon:<Abs/>, auth:true, link:'absences', univers:["TIMED", "RESTIMED"]},
    {code:"creneaux",libelle:"creneaux", icon:<Cren/>, auth:false, link:'creneaux', univers:["TIMED"]},
    {code:"pl",libelle:"planning", icon:<Pl/>, auth:true, link:'planning', univers:["TIMED", "RESTIMED"]},
    {code:"messagerie",libelle:"messagerie", icon:<Mess/>, auth:true, link:'messagerie', isMessagerie:true, univers:["TIMED", "RESTIMED"]},
    {code:"profil",libelle:"profil", icon:<Profil/>, auth:true, link:'profil', univers:["TIMED", "RESTIMED"]},
]


const TabNavBarCtrl = ({className}:TabNavBarCtrlProps)=>{
    const UserQuery = useGetUserMob();
    const CentreQuery = useGetCentreActuel();
    const myNavsBar = useMemo(()=>{
        let Navs:OneNav[] = [];
        if(UserQuery.data && CentreQuery.data){
            const CentreParams = CentreQuery.data.parameters
            const ParamsFilterInterne = CentreParams.find(cp=>cp.Parameter.id === 23);
            const isInterneCren = (!!ParamsFilterInterne && ParamsFilterInterne.statut)
            const UniversCurrent = process.env.REACT_APP_UNIVERS||"TIMED"
            const MyTabNav = TabNav.filter(t=>t.univers.indexOf(UniversCurrent)!==-1)
            const mobParams = UserQuery.data.mobParams;
            MyTabNav.map(item=>{
                let auth = item.auth;
                if(isInterneCren && UserQuery.data.idFA === 1 && item.code === "creneaux"){
                    auth = true;
                } else {
                    for (const [k,v] of Object.entries(mobParams)){
                        if(k === item.code){
                            auth = v === 1;
                        }
                    }
                }
                Navs.push({...item, auth:auth});
                return item;
            })
        }
        return Navs
    }, [UserQuery.data, CentreQuery.data])

    return (
        <div className={`TabBar ${className}`}>
            {UserQuery.isLoading ? 'chargement':
                myNavsBar.filter(i=>i.auth).map((item:OneNav, idx:number)=>(
                    item.link==='messagerie' ?
                        <TimedOneNavMessagerie
                            key={`oneNavBar${idx}`}
                            navTo={item.link}
                            icon={item.icon}
                            libelle={item.libelle}
                        />:
                        <TimedOneNav
                            key={`oneNavBar${idx}`}
                            navTo={item.link}
                            icon={item.icon}
                            libelle={item.libelle}
                        />
                ))
            }
        </div>
    )
}

export const TabNavBar = styled(TabNavBarCtrl)`
  width: 100%;
  background: #FAFCFC;
  display: flex;
  padding: 8px 8px 68px 8px;
  border-top: solid 1px ${props => props.theme.NeutreMegaLight};
`