import React, { Suspense } from 'react';
import './App.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {ThemeProvider} from "styled-components";
import GlobalStyle from './assets/styles/Global';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "./components/security/PrivateRoute";
import PublicRoute from "./components/security/PublicRoute";
import {ToastContainer} from "react-toastify";
import ApplicationController from "./Pages/ApplicationController";
import Login from "./Pages/Login/Login";
import {ViewportProvider} from "./services/providers/ViewportProvider";
import TimedSuspense from "./components/organisms/layouts/TimedSuspense";
import PwdRetrieve from "./Pages/PwdRetrieve/PwdRetrieve";
import RestimedTheme from "./assets/styles/RestimedTheme";
import { ReactQueryDevtools } from "react-query/devtools";


export const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            suspense:true
        }
    }
});


function App() {
  return (
      <ThemeProvider theme={RestimedTheme}>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
            <ViewportProvider>
              <div className="App">
                  <Suspense fallback={<TimedSuspense/>}>
                  <Routes>
                      <Route element={<PrivateRoute from={"App"}/>}>
                          <Route path="/timed_application/*" element={<ApplicationController />} />
                      </Route>
                      <Route element={<PublicRoute/>}>
                          <Route path="/" element={<Navigate to="/login" />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/pwd_retrieve" element={<PwdRetrieve />} />
                      </Route>

                      {/*<Route path="*" element={<p>Non trouvé</p>} />*/}
                  </Routes>
                  </Suspense>
                  <ToastContainer
                      position="bottom-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                  />
              </div>
            </ViewportProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
  );
}

export default App;
