import React, {useEffect, useState} from "react";
import styled from "styled-components";
import logo from "../../assets/images/LogoWhiteRT.png";
import fond from "../../assets/images/fond_1_r.png";
import hom from "../../assets/images/Rest-mobile.png";
import {LoginFormMobile} from "./LoginForm/LoginFormMobile";
import {useLocation} from "react-router-dom";
import {version_timed} from "../../index";

interface LoginMobileCtrlProps{
    className?:string;
}

const LoginMobileCtrl = ({className}:LoginMobileCtrlProps)=>{
    const location = useLocation();
    const [full, setFull] = useState(false);
    return (
        <div className={`MobileLogin ${className}`}>
            <div className={`version`}>{version_timed}</div>
            <div className={`block-head`} style={{height:full ? "106%" : "50%"}}>
                <div className={"content-fond"}>
                    <img src={fond} alt={"fond"}/>
                </div>
                <div className={`content-front`}>
                    <div className={`logo-place`} onClick={()=>setFull(_f=>!_f)}>
                        <img src={logo} alt={"Timed application"}/>
                    </div>
                    <div className={`homme-place ${full ? 'scaler' : ''}`}>
                        <img src={hom} alt={"Timed mobile"}/>
                    </div>
                </div>
            </div>
            {!full &&
                <div className={`form-content`}>
                    <h1>Bienvenue</h1>
                    <LoginFormMobile setFull={setFull} location={location}/>
                </div>
            }
        </div>
    )
}

const LoginMobile = styled(LoginMobileCtrl)`
    height: 100vh;
  position: relative;
  .form-content{
    h1{
      padding-left: 2rem;
    }
  }
  .version{
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
  h1{
    margin-bottom: 20px;
  }
  .form-content{
    height: 50vh;
    padding: 1rem 2rem;
  }
  .content-front{
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .homme-place{
      flex-grow: 1;
      text-align: center;
      position: relative;
      &.scaler{
        img{
          transform: translate(-50%,-50%) scale(1.3);
        }
      }
      img{
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
        width: 40%;
        transition: all 0.3s;
      }
    }
    .logo-place{
      text-align: center;
      padding: 1rem;
      height: 80px;
      margin-top: 80px;
      img{
        height: 90%;
        width: auto;
      }
    }
  }
  .logo-place{
    
  }
  .block-head{
    width: 100%;
    position: relative;
    transition: height 0.4s;
    .content-fond{
      left: 0;
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;
      width: 100%;
      height: 100%;
      .img{
        position: absolute;
        bottom: 0;
      }
    }
  }
`

export default LoginMobile;