import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";

interface TimedOneNavCtrlProps{
    className?:string;
    navTo:string;
    icon:React.ReactNode;
    libelle:string;
}



const TimedOneNavCtrl = ({className, navTo, icon, libelle}:TimedOneNavCtrlProps)=>{
    const location = useLocation();
    const [domain, setDomain] = useState('');
    useEffect(()=>{
        const TabLoc = location.pathname.split('/');
        setDomain(TabLoc[2]);
    }, [location])
    return (
        <NavLink to={`/timed_application/${navTo}`} className={`TimedTab ${className} ${domain === navTo ? 'isActive' : ''} `}>
            <div className={`icon-place`}>{icon}</div>
            <div className={`lib-place`}>{libelle}</div>
        </NavLink>
    )
}

const TimedOneNav = styled(TimedOneNavCtrl)`
  flex-grow: 1;
  text-decoration: none;
  text-align: center;
  .lib-place{
    font-size: 10px;
    text-align: center;
    color:${props => props.theme.Dark};
  }
  svg{
    .path_to_fill{
      stroke: ${props=>props.theme.Dark};
    }
    .path_to_fill2{
      fill: ${props=>props.theme.Dark};
    }
    .path_to_fill_primary{
      fill: ${props=>props.theme.Dark};
    }
    .path_to_fill_white{
      fill: ${props=>props.theme.Dark};
    }
  }
    &.isActive{
      svg{
        .path_to_fill2{
          fill: ${props=>props.theme.Primary};
        }
        .path_to_fill_primary{
          fill: ${props=>props.theme.Primary};
        }
        .path_to_fill{
          fill:${props => props.theme.Primary};
          stroke:${props => props.theme.Primary};
        }
        .path_to_fill_white{
          fill: white;
        }
      }
      .lib-place{
        color:${props => props.theme.Primary};
      }
    }
`

export default TimedOneNav;